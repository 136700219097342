import clsx from 'clsx'
import { memo } from 'react'

export const SubcommentLineIcon = memo(function SubcommentLineIcon(props: {
  className: string
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('h-auto', props.className)}
      fill="none"
      viewBox="0 0 19 9"
      stroke="currentColor"
    >
      <path d="M2.9,0L2.9,0C1.3,0,0,1.4,0,3v6h1V3c0-1.1,0.9-2,2-2h16V0H2.9z"></path>
    </svg>
  )
})
