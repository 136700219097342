import clsx from 'clsx'
import { memo } from 'react'

export const AddBookmarkIcon = memo(function AddBookmarkIcon(props: {
  className: string
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="currentColor"
      className={clsx('w-5 aspect-square', props.className)}
    >
      <path
        d="M13.5 3h-.543c-1.363 0-2.448 0-3.322.072-.897.073-1.66.227-2.36.585A6.01 6.01 0 0 0 4.653 6.29c-.356.701-.508 1.467-.582 2.366C4 9.533 4 10.621 4 11.991v8.587c0 1.011 0 1.84.058 2.48.058.635.183 1.285.588 1.8A2.997 2.997 0 0 0 6.974 26c.656.006 1.247-.296 1.78-.64.54-.347 1.192-.856 1.986-1.477l1.911-1.492c.776-.606.943-.712 1.089-.752a.993.993 0 0 1 .52 0c.146.04.313.146 1.089.752l1.91 1.492c.795.621 1.447 1.13 1.986 1.477.534.345 1.125.646 1.78.64a2.997 2.997 0 0 0 2.329-1.143c.405-.514.53-1.164.588-1.799.058-.64.058-1.469.058-2.48v-2.72a1 1 0 0 0-2 0v2.67c0 1.074-.001 1.81-.05 2.35-.05.557-.14.707-.167.741a.997.997 0 0 1-.774.381c-.041 0-.212-.02-.68-.321-.454-.292-1.034-.743-1.878-1.403l-1.87-1.461-.125-.097c-.576-.452-1.083-.849-1.67-1.009a2.992 2.992 0 0 0-1.571 0c-.588.16-1.095.557-1.671 1.009l-.124.097-1.871 1.461c-.844.66-1.423 1.11-1.877 1.403-.469.302-.64.321-.68.321a.997.997 0 0 1-.775-.38c-.027-.035-.117-.185-.167-.742-.05-.54-.05-1.276-.05-2.35v-8.494c0-1.422 0-2.43.065-3.217.063-.776.183-1.25.372-1.623a4.01 4.01 0 0 1 1.749-1.756c.37-.19.84-.31 1.612-.373.784-.064 1.786-.065 3.203-.065h.499a1 1 0 1 0 0-2M27 9a6 6 0 1 0-12 0 6 6 0 0 0 12 0m-6.993-3.117A1 1 0 0 1 22 6v2h2l.117.007A1 1 0 0 1 24 10h-2v2l-.007.117A1 1 0 0 1 20 12v-2h-2l-.117-.007A1 1 0 0 1 18 8h2V6z"
        clipRule="evenodd"
      />
    </svg>
  )
})
