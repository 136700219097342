import clsx from 'clsx'
import { memo } from 'react'
import { isRanobe } from 'src/app/lib/utils/main.util'

export const LogoIcon = memo(function LogoIcon(props: { className?: string }) {
  if (isRanobe()) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <path
          strokeWidth={1}
          strokeLinecap="butt"
          strokeLinejoin="miter"
          stroke="#f15a25"
          strokeMiterlimit={4}
          // style="fill:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke:#f15a25;stroke-opacity:1;stroke-miterlimit:4"
          d="M1 .5h14M1.5 1v14M1 15.5h14M14.5 1v2M14.5 15v-2M5 4h6M6.5 4v5M10.5 4v5M7 8.5h3M6.5 9v2M5 11h3"
          transform="scale(1.5)"
        />
      </svg>
    )
  }

  return (
    <svg
      className={clsx('w-auto h-6', props.className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      version="1.1"
      width="28"
      height="28"
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M221.522 153.554c107.13 2.429 230.451-5.86 219.508-25.569L309.897 25.993a87.782 87.782 0 0 0-50.008-18.402C154.52 32.464 152.207 139.053 221.522 153.554z"
          fill="#fbeb78"
          data-original="#fbeb78"
        />
        <path
          d="M202.103 25.993 70.97 127.985c-8.662 15.009 65.753 23.646 150.552 25.569 3.353-49.917 14.646-101.023 38.367-145.963a87.748 87.748 0 0 0-57.786 18.402z"
          fill="#ffc64f"
          data-original="#ffc64f"
        />
        <path
          d="M383.997 297.075c-33.085 25.772 11.746 83.323 44.83 57.552l65.383-50.915c11.587-9.026 13.655-25.734 4.639-37.32l-12.169-15.613c-9.016-11.587-25.724-13.665-37.31-4.639-68.068 53.031-65.24 50.802-65.373 50.935z"
          fill="#fb7a6e"
          data-original="#fb7a6e"
        />
        <path
          d="M306.214 335.319c16.709 33.833 50.871 57.9 96.257 63.064 16.732 2.967 32.129-9.872 32.129-26.958a27.244 27.244 0 0 0-5.773-16.798l-44.83-57.552c-27.754-33.149-90.835-3.925-77.783 38.244z"
          fill="#f45b5b"
          data-original="#f45b5b"
        />
        <path
          d="M128.015 297.075c33.085 25.772-11.746 83.323-44.83 57.552l-65.383-50.915c-11.587-9.026-13.655-25.734-4.639-37.32l12.169-15.613c9.016-11.587 25.724-13.665 37.31-4.639 68.068 53.031 65.24 50.802 65.373 50.935z"
          fill="#fb7a6e"
          data-original="#fb7a6e"
        />
        <path
          d="M205.798 335.319c-16.709 33.833-50.871 57.9-96.257 63.064-16.732 2.967-32.129-9.872-32.129-26.958a27.244 27.244 0 0 1 5.773-16.798l44.83-57.552c27.755-33.149 90.836-3.925 77.783 38.244z"
          fill="#f45b5b"
          data-original="#f45b5b"
        />
        <path
          d="m327.076 338.502-71.377 112.091-71.397-112.101c.05-.02.232-.306.282-.326 35.442-40.856 109.904-40.59 142.492.336z"
          fill="#ffb09e"
          data-original="#ffb09e"
        />
        <path
          d="M427.791 165.636c-3.105-19.428-111.051-28.936-205.212-26.583-107.145 21.563-80.097 155.317 34.033 169.239 64.231-.629 129.185-22.958 161.901-69.572 8.344-23.519 11.414-48.542 9.278-73.084z"
          fill="#ffdfcf"
          data-original="#ffdfcf"
        />
        <path
          d="M222.579 139.053c-71.293 1.781-134.685 10.361-138.682 26.583-2.158 24.79 1.02 49.797 9.27 73.084h.008c26.623 46.005 94.616 70.246 163.438 69.572-31.469-49.779-39.404-111.425-34.034-169.239z"
          fill="#ffcebf"
          data-original="#ffcebf"
        />
        <path
          d="M418.514 238.72c-52.737 15.968-119.38 23.045-184.462 21.031-70.675 8.548-8.896 91.402 58.12 90.256l.011.01c58.151-12.541 106.249-54.542 126.331-111.297z"
          fill="#4c6371"
          data-original="#4c6371"
        />
        <path
          d="M292.171 350.007c-28.515-25.076-47.114-56.351-58.12-90.256-50.265-1.555-99.6-8.533-140.877-21.031 28.293 80.633 113.008 129.77 198.997 111.287z"
          fill="#354650"
          data-original="#354650"
        />
        <path
          d="M244.621 450.593c-4.297 25.604-28.448 48.719-68.482 53.907H96.448c-12.069 0-21.426-10.562-19.94-22.541 6.104-49.038 31.74-91.47 68.114-119.923 34.402-14.093 106.99 54.972 99.999 88.557z"
          fill="#2c373f"
          data-original="#2c373f"
        />
        <path
          d="M255.699 416.998c22.557 24.248-9.237 55.875-30.543 33.595l-80.534-88.556a180.435 180.435 0 0 1 39.68-23.545l71.397 78.506z"
          fill="#354650"
          data-original="#354650"
        />
        <path
          d="M327.076 338.502c.05-.02.1-.04.151-.07a180.607 180.607 0 0 1 39.65 23.464v.01C401.251 406.32 297.668 499.48 237.214 504.5h-61.076l150.938-165.998z"
          fill="#4c6371"
          data-original="#4c6371"
        />
        <path
          d="M435.181 481.959c1.486 11.978-7.872 22.541-19.94 22.541H237.214l129.662-142.594v-.01c37.438 29.181 62.345 72.152 68.305 120.063z"
          fill="#354650"
          data-original="#354650"
        />
        <path
          d="m176.139 504.5 150.947-166.008M237.214 504.5l129.662-142.594M184.091 338.261l71.608 78.737v.01M225.156 450.593l-80.524-88.546M193.247 193.499v12.048M318.652 193.499v12.048"
          style={{
            strokeWidth: 15,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          fill="none"
          stroke="#000000"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          data-original="#000000"
        />
        <path
          d="m184.452 338.432-.151.06c-57.772 24.91-99.771 79.058-107.794 143.467-1.486 11.978 7.872 22.541 19.94 22.541h217.786M327.227 338.432c57.853 24.89 99.932 79.008 107.954 143.528 1.486 11.978-7.872 22.541-19.94 22.541h-65.865M83.185 354.627l-65.383-50.915c-11.587-9.026-13.655-25.734-4.639-37.32l12.169-15.613c9.016-11.587 25.724-13.665 37.31-4.639l65.383 50.925-.01.01"
          style={{
            strokeWidth: 15,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          fill="none"
          stroke="#000000"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          data-original="#000000"
        />
        <path
          d="M109.541 398.383c-16.732 2.967-32.129-9.872-32.129-26.958a27.244 27.244 0 0 1 5.773-16.798l44.83-57.552M383.985 297.065h-.01l44.84 57.562h.01l65.373-50.915c11.587-9.026 13.655-25.734 4.639-37.32l-12.169-15.613c-9.016-11.587-25.724-13.665-37.31-4.639l-65.373 50.925z"
          style={{
            strokeWidth: 15,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          fill="none"
          stroke="#000000"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          data-original="#000000"
        />
        <path
          d="M383.844 296.885c1.213 1.456 8.628 11.072 44.981 57.742 9.296 11.962 7.125 29.14-4.779 38.405-6.466 5.04-14.478 6.717-21.938 5.291M93.165 238.721c-8.341-23.549-11.401-48.57-9.267-73.084"
          style={{
            strokeWidth: 15,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          fill="none"
          stroke="#000000"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          data-original="#000000"
        />
        <path
          d="M427.791 165.636c4.182 48.048-11.63 95.429-43.947 131.248-68.183 75.619-186.967 76.311-255.829.191l-.06-.06c-15.151-16.767-27.079-36.527-34.78-58.295"
          style={{
            strokeWidth: 15,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          fill="none"
          stroke="#000000"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          data-original="#000000"
        />
        <path
          d="M93.165 238.721h.01c94.152 28.508 231.231 28.494 325.339 0"
          style={{
            strokeWidth: 15,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          fill="none"
          stroke="#000000"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          data-original="#000000"
        />
        <path
          d="m355.498 127.985-58.382-95.422c-9.31-15.209-23.111-23.478-37.308-24.817-42.626 8.805-88.817 116.892-37.856 138.833 60.905 3.371 140.596-2.826 133.546-18.594z"
          fill="#ffc64f"
          data-original="#ffc64f"
        />
        <path
          d="m214.315 32.563-58.382 95.422c-5 10.213 26.569 16.41 66.02 18.594 3.815-52.812 18.984-103.018 37.856-138.833-16.901-1.594-34.365 6.643-45.494 24.817z"
          fill="#ffb320"
          data-original="#ffb320"
        />
        <path
          d="m454.791 138.688-13.761-10.703H223.772c-40.398 6.861-40.565 35.811-2.693 37.652h224.465c14.341-.001 20.566-18.145 9.247-26.949z"
          fill="#f68953"
          data-original="#f68953"
        />
        <path
          d="m70.97 127.985-13.761 10.703c-11.319 8.804-5.094 26.949 9.246 26.949h154.624c.235-12.672 1.172-25.273 2.693-37.652H70.97z"
          fill="#df6d2b"
          data-original="#df6d2b"
        />
        <path
          d="M253.738 7.53c1.235-.01 2.47-.02 3.705 0"
          style={{
            strokeWidth: 15,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          fill="none"
          stroke="#000000"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          data-original="#000000"
        />
        <path
          d="m355.497 127.985-58.378-95.424c-20.405-33.321-62.306-33.508-82.807 0l-58.378 95.424M437.116 127.985H74.306"
          style={{
            strokeWidth: 15,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          fill="none"
          stroke="#000000"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          data-original="#000000"
        />
        <path
          d="m149.612 66.819 52.491-40.824c31.708-24.664 76.109-24.646 107.794 0L454.79 138.688c11.316 8.805 5.09 26.948-9.247 26.948H66.457c-14.338 0-20.563-18.143-9.247-26.948l64.66-50.292"
          style={{
            strokeWidth: 15,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          fill="none"
          stroke="#000000"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          data-original="#000000"
        />
      </g>
    </svg>
  )
})
