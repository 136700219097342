import clsx from 'clsx'
import { memo } from 'react'

export const SendIcon = memo(function SendIcon(props: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      focusable="false"
      viewBox="0 0 24 24"
      className={clsx('aspect-square', props.className)}
    >
      <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
    </svg>
  )
})
