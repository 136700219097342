import clsx from 'clsx'
import { memo } from 'react'

export const ZipIcon = memo(function ZipIcon(props: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('aspect-square', props.className)}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M13.5 16H12v-1h-1.5c-.3 0-.5.2-.5.5v5c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5v-4c0-.3-.2-.5-.5-.5zm-1 4h-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1c.3 0 .5.2.5.5s-.2.5-.5.5zM10.5 13c-.3 0-.5.2-.5.5s.2.5.5.5H12v-1zM10.5 11c-.3 0-.5.2-.5.5s.2.5.5.5H12v-1zM10.5 9c-.3 0-.5.2-.5.5s.2.5.5.5H12V9zM10.5 7c-.3 0-.5.2-.5.5s.2.5.5.5H12V7zM10.5 5c-.3 0-.5.2-.5.5s.2.5.5.5H12V5zM10.5 3c-.3 0-.5.2-.5.5s.2.5.5.5H12V3zM10.5 1c-.3 0-.5.2-.5.5s.2.5.5.5H12V1zM13.5 14c.3 0 .5.2.5.5s-.2.5-.5.5H12v-1zM13.5 12c.3 0 .5.2.5.5s-.2.5-.5.5H12v-1zM13.5 10c.3 0 .5.2.5.5s-.2.5-.5.5H12v-1zM13.5 8c.3 0 .5.2.5.5s-.2.5-.5.5H12V8zM13.5 6c.3 0 .5.2.5.5s-.2.5-.5.5H12V6zM13.5 4c.3 0 .5.2.5.5s-.2.5-.5.5H12V4zM13.5 2c.3 0 .5.2.5.5s-.2.5-.5.5H12V2z"
          fill="currentColor"
        />
        <path
          d="M15 0H6C4.3 0 3 1.3 3 3v18c0 1.7 1.3 3 3 3h12c1.7 0 3-1.3 3-3V6zm.5 1.6 3.9 3.9h-2.7c-.6 0-1.2-.5-1.2-1.2zM20 21c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h8.5v3.3c0 1.2 1 2.2 2.2 2.2H20z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
})
