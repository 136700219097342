import clsx from 'clsx'
import { memo } from 'react'

export const CompleteBookmarkIcon = memo(function CompleteBookmarkIcon(props: {
  className: string
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="currentColor"
      className={clsx('w-5 aspect-square', props.className)}
    >
      <path
        fillRule="evenodd"
        d="M21 3a6 6 0 1 0 0 12 6 6 0 0 0 0-12m2.78 4.22a.75.75 0 0 0-1.06 0l-2.47 2.47-.97-.97a.75.75 0 1 0-1.06 1.06l1.5 1.5a.75.75 0 0 0 1.06 0l3-3a.75.75 0 0 0 0-1.06M12.957 3h.543a1 1 0 1 1 0 2h-.499c-1.417 0-2.42 0-3.203.065-.772.063-1.243.183-1.612.373a4.01 4.01 0 0 0-1.75 1.756c-.188.373-.308.847-.371 1.623C6 9.605 6 10.612 6 12.034v8.493c0 1.075.001 1.811.05 2.35.05.558.14.708.167.742a.997.997 0 0 0 .775.381c.04 0 .211-.02.68-.321.454-.292 1.033-.743 1.877-1.403l1.87-1.461.124-.097c.578-.452 1.084-.849 1.672-1.009a2.992 2.992 0 0 1 1.57 0c.588.16 1.095.557 1.671 1.009l.124.097 1.871 1.461c.844.66 1.424 1.11 1.877 1.403.469.302.64.321.68.321a.997.997 0 0 0 .775-.38c.027-.035.117-.185.167-.742.049-.54.05-1.276.05-2.35v-2.67a1 1 0 0 1 2 0v2.72c0 1.011 0 1.84-.058 2.48-.058.635-.183 1.285-.588 1.8A2.997 2.997 0 0 1 21.026 26c-.656.006-1.247-.296-1.78-.64-.54-.347-1.192-.856-1.986-1.477l-1.911-1.492c-.776-.606-.943-.712-1.089-.752a.993.993 0 0 0-.52 0c-.146.04-.313.146-1.089.752l-1.91 1.492c-.795.621-1.447 1.13-1.986 1.477-.534.345-1.125.646-1.78.64a2.997 2.997 0 0 1-2.329-1.143c-.405-.514-.53-1.164-.588-1.799C4 22.418 4 21.59 4 20.578V11.99c0-1.37 0-2.458.071-3.336.074-.899.226-1.665.582-2.366a6.01 6.01 0 0 1 2.622-2.632c.7-.358 1.463-.512 2.36-.585C10.509 3 11.594 3 12.957 3"
        clipRule="evenodd"
      />
    </svg>
  )
})
