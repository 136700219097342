import clsx from 'clsx'
import { memo } from 'react'

export const ArrowDownMicroIcon = memo(function ArrowDownMicroIcon(props: {
  className: string
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="currentColor"
      className={clsx('w-4 aspect-square', props.className)}
    >
      <path
        fillRule="evenodd"
        d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
        clipRule="evenodd"
      />
    </svg>
  )
})
