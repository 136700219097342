import clsx from 'clsx'
import { memo } from 'react'

export const ShurikenIcon = memo(function ShurikenIcon(props: {
  className: string
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('w-5 aspect-square', props.className)}
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          className="fill-primary"
          d="M294.68 174.523c-28.892 3.537-46.129 18.521-44.747 44.319 23.036-3.734 43.719 14.048 43.719 37.158 0 23.151-20.723 40.889-43.724 37.157-4.37 28.829 15.777 41.655 44.705 44.32 31.259-14.303 52.936-45.402 52.936-81.477-.001-35.908-21.516-67.102-52.889-81.477z"
        ></path>
        <path
          className="fill-primary/85"
          d="M249.927 293.157c-17.905-2.905-31.579-18.432-31.579-37.157 0-18.727 13.676-34.255 31.584-37.158 9.043-19.498 24.974-35.272 44.747-44.319-18.264-8.368-38.276-10.266-56.693-6.651-49.074 9.61-81.784 56.703-71.778 105.809 9.826 48.337 57.95 80.235 107.805 70.448a92.034 92.034 0 0 0 20.618-6.651c-19.756-9.053-35.67-24.827-44.704-44.321z"
        ></path>
        <path
          className="fill-primary opacity-80"
          d="M386.696 189.984c-40.098-6.396-65.53-52.594-43.786-90.303a142.71 142.71 0 0 1 59.811-56.357l12.069-6.034c8.153-4.076 10.231-12.641 7.711-19.549l-32.107-2.376c-155.748 10.094-156.952 149.168-150.69 159.408 45.308-9.062 88.676 20.596 97.522 64.931L494.259 422.5c5.683-2.058 10.241-7.249 10.241-14.739v-79.47c0-69.781-51.025-127.644-117.804-138.307z"
          // fill="#e9efff"
          // data-original="#e9efff"
        ></path>
        <path
          className="fill-primary opacity-80"
          d="M407.761 7.5h-79.47c-69.781 0-127.644 51.025-138.306 117.804-6.073 38.075-50.043 66.163-88.265 44.941-24.699-13.725-45.262-34.72-58.395-60.965L37.29 97.211C33.214 89.058 24.649 86.98 17.741 89.5l-3.194 35.305c15.395 141.235 76.399 163.476 160.226 147.49-9.062-45.308 20.596-88.676 64.931-97.522l6.627-22.069c19.82-66.096 74.781-115.655 142.564-128.567l33.605-6.396C420.442 12.058 415.251 7.5 407.761 7.5zM487.863 388.895c-12.912-67.783-62.471-122.744-128.567-142.564l-22.069-6.627c9.062 45.308-20.596 88.676-64.931 97.522L89.5 494.259c2.058 5.683 7.249 10.241 14.739 10.241h79.47c69.781 0 127.644-51.025 138.307-117.804 6.395-40.098 52.595-65.53 90.303-43.786a142.71 142.71 0 0 1 56.357 59.811l6.034 12.069c4.076 8.153 12.641 10.231 19.549 7.711l-6.396-33.606z"
          // fill="#d3dcfb"
          // data-original="#d3dcfb"
        ></path>
        <path
          className="fill-primary opacity-80"
          d="m272.296 337.227-6.627 22.069c-19.82 66.096-74.781 115.655-142.564 128.567L89.5 494.259c-2.52-6.908-.442-15.472 7.711-19.549l9.428-4.719c26.798-13.394 48.304-34.298 62.492-59.409 20.866-36.93-3.979-82.22-43.826-88.566C58.525 311.353 7.5 253.49 7.5 183.709v-79.47c0-7.49 4.558-12.681 10.241-14.739l6.396 33.605c12.912 67.783 62.471 122.744 128.567 142.564l22.069 6.627c7.57 37.943 41.065 66.538 81.227 66.538a83.082 83.082 0 0 0 16.296-1.607z"
          // fill="#bec8f7"
          // data-original="#bec8f7"
        ></path>
        <path
          d="M45.81 279.96C22.06 254.87 7.5 220.99 7.5 183.71v-79.47c0-7.49 4.56-12.68 10.24-14.74 6.91-2.52 15.47-.44 19.55 7.71l6.03 12.07a142.79 142.79 0 0 0 58.4 60.96c38.22 21.23 82.19-6.86 88.26-44.94C200.65 58.53 258.51 7.5 328.29 7.5h79.47c16.59 0 21.88 22.37 7.03 29.79l-12.07 6.03a142.789 142.789 0 0 0-59.81 56.36c-21.74 37.71 3.69 83.91 43.79 90.3a139.27 139.27 0 0 1 52.4 19.79M466.09 231.94c23.81 25.1 38.41 59.02 38.41 96.35v79.47c0 16.59-22.37 21.88-29.79 7.03l-6.03-12.07a142.789 142.789 0 0 0-56.36-59.81c-37.71-21.74-83.91 3.69-90.3 43.79-10.67 66.77-68.53 117.8-138.31 117.8h-79.47c-16.59 0-21.88-22.37-7.03-29.79l9.43-4.72c26.8-13.39 48.3-34.3 62.49-59.41 20.86-36.92-3.97-82.22-43.83-88.56a139.197 139.197 0 0 1-52.51-19.86"
          style={{
            strokeWidth: 15,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          fill="none"
          stroke="#000000"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          data-original="#000000"
        ></path>
        <path
          d="M338.833 256c0 40.162-28.595 73.656-66.538 81.227-45.31 9.062-88.677-20.598-97.522-64.931-10.265-51.323 29.035-99.129 81.227-99.129 45.73 0 82.833 37.085 82.833 82.833z"
          style={{
            strokeWidth: 15,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          fill="none"
          stroke="#000000"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          data-original="#000000"
        ></path>
        <circle
          cx="256"
          cy="256"
          r="37.652"
          style={{
            strokeWidth: 15,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          fill="none"
          stroke="#000000"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          data-original="#000000"
        ></circle>
        <path
          d="m239.704 174.773 6.627-22.069c19.82-66.096 74.781-115.655 142.564-128.567l33.605-6.396M174.773 272.296l-22.069-6.627c-66.096-19.82-115.655-74.781-128.567-142.564L17.741 89.5M272.296 337.227l-6.627 22.069c-19.82 66.096-74.781 115.655-142.564 128.567L89.5 494.259M337.227 239.704l22.069 6.627c66.096 19.82 115.655 74.781 128.567 142.564l6.396 33.605"
          style={{
            strokeWidth: 15,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          fill="none"
          stroke="#000000"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  )
})
