import { Fragment } from 'react'
import {
  AddBookmarkIcon,
  AppleIcon,
  ArrowDownMicroIcon,
  ArrowLongDownIcon,
  ArrowRightLeftIcon,
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  BellIcon,
  BookmarkFullIcon,
  BookmarkIcon,
  BookmarksIcon,
  BrushIcon,
  CheckIcon,
  ChevronUpIcon,
  CloseIcon,
  CommentIcon,
  CompleteBookmarkIcon,
  DangerIcon,
  FunnelIcon,
  GoogleIcon,
  GooglePlayIcon,
  HeartFullIcon,
  HeartIcon,
  InfoIcon,
  ListBulletIcon,
  ListIcon,
  LoaderIcon,
  LockIcon,
  LogInIcon,
  LogoIcon,
  MoonIcon,
  NinjaIcon,
  OpenLockIcon,
  SearchIcon,
  SendIcon,
  SettingIcon,
  ShurikenIcon,
  SnowflakesIcon,
  SparklesIcon,
  SpinnerIcon,
  StarIcon,
  SubcommentLineIcon,
  SuccessIcon,
  SunIcon,
  TelegramIcon,
  TrashIcon,
  TrophyIcon,
  UserCircleIcon,
  UserIcon,
  VkIcon,
  WinterLogoIcon,
  ZipIcon,
} from 'src/components/icons'
import { IconType } from './types'

export const Icon = function Icon(props: {
  name: IconType
  className?: string
}) {
  const { name, className = '' } = props

  switch (name) {
    case 'heart':
      return <HeartIcon className={className} />

    case 'heartFull':
      return <HeartFullIcon className={className} />

    case 'spinner':
      return <SpinnerIcon className={className} />

    case 'settings':
      return <SettingIcon className={className} />

    case 'sparkles':
      return <SparklesIcon className={className} />

    case 'userCircle':
      return <UserCircleIcon className={className} />

    case 'ninja':
      return <NinjaIcon className={className} />

    case 'list':
      return <ListIcon className={className} />

    case 'subcommentLine':
      return <SubcommentLineIcon className={className} />

    case 'send':
      return <SendIcon className={className} />

    case 'comment':
      return <CommentIcon className={className} />

    case 'bookmark':
      return <BookmarkIcon className={className} />

    case 'brush':
      return <BrushIcon className={className} />

    case 'bookmarkFull':
      return <BookmarkFullIcon className={className} />

    case 'close':
      return <CloseIcon className={className} />

    case 'chevronUp':
      return <ChevronUpIcon className={className} />

    case 'arrowRightLeft':
      return <ArrowRightLeftIcon className={className} />

    case 'arrowLongDown':
      return <ArrowLongDownIcon className={className} />

    case 'check':
      return <CheckIcon className={className} />

    case 'danger':
      return <DangerIcon className={className} />

    case 'logIn':
      return <LogInIcon className={className} />

    case 'user':
      return <UserIcon className={className} />

    case 'telegram':
      return <TelegramIcon className={className} />

    case 'moon':
      return <MoonIcon className={className} />

    case 'sun':
      return <SunIcon className={className} />

    case 'arrowRightOnRectangle':
      return <ArrowRightOnRectangleIcon className={className} />

    case 'addBookmark':
      return <AddBookmarkIcon className={className} />

    case 'completeBookmark':
      return <CompleteBookmarkIcon className={className} />

    case 'listBullet':
      return <ListBulletIcon className={className} />

    case 'search':
      return <SearchIcon className={className} />

    case 'bars3':
      return <Bars3Icon className={className} />

    case 'logo':
      return <LogoIcon className={className} />

    case 'winterLogo':
      return <WinterLogoIcon className={className} />

    case 'trash':
      return <TrashIcon className={className} />

    case 'google':
      return <GoogleIcon className={className} />

    case 'vk':
      return <VkIcon className={className} />

    case 'lock':
      return <LockIcon className={className} />

    case 'openLock':
      return <OpenLockIcon className={className} />

    case 'trophy':
      return <TrophyIcon className={className} />

    case 'star':
      return <StarIcon className={className} />

    case 'bookmarks':
      return <BookmarksIcon className={className} />

    case 'funnel':
      return <FunnelIcon className={className} />

    case 'shuriken':
      return <ShurikenIcon className={className} />

    case 'funnel':
      return <FunnelIcon className={className} />

    case 'info':
      return <InfoIcon className={className} />

    case 'zip':
      return <ZipIcon className={className} />

    case 'bell':
      return <BellIcon className={className} />

    case 'snowflakes':
      return <SnowflakesIcon className={className} />

    case 'googlePlay':
      return <GooglePlayIcon className={className} />

    case 'apple':
      return <AppleIcon className={className} />

    case 'loader':
      return <LoaderIcon className={className} />

    case 'success':
      return <SuccessIcon className={className} />

    case 'arrowDownMicro':
      return <ArrowDownMicroIcon className={className} />

    default:
      return <Fragment />
  }
}
