import clsx from 'clsx'
import { memo } from 'react'

export const ChevronUpIcon = memo(function ChevronUpIcon(props: {
  className: string
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={clsx('w-5 aspect-square', props.className)}
    >
      <path
        fillRule="evenodd"
        d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
        clipRule="evenodd"
      />
    </svg>
  )
})
